<template>
  <popup :width="width" :heightPopup="height" :closePopupEvent="closePopupCB">
    <!-- <h6 slot="header">{{header}}</h6> -->
    <div class="session-exp-popup">
      <div class="popup-form">
        {{ message }}
      </div>
    </div>
  </popup>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";

export default {
  name: "delete",
  props: {
    closePopupCB: {
      type: Function,
    },
    message: {
      type: String,
    },
    page: {
      type: String,
    },
  },
  data() {
    return {
      width: "35%",
      height: "55%",
      disabled: true,
    };
  },
  methods: {
    actDone() {
      this.closePopupCB();
    },
  },
  components: {
    popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue"),
  },
  mixins: [appMixins],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.popup-box{
background-color: black;
.session-exp-popup {
  text-align: center;
  .popup-form {
    padding: 8% 15%;
    font-size:1.3rem;
    color:white;
    .heading {
      font-size: 1.5rem;
      font-weight: bold;
      color: #ffffff;
    }
    .question {
      font-size: 1.2rem;
      font-weight: bold;
      margin: 7% 0;
      color: #ffffff;
    }
    .sec-2 {
      font-size: 1rem;
    }
    .image {
      width: 25%;
    }
    .tc {
      list-style: disc;
      padding: 10px 0px;
      color: #ffffff;
    }
    .confirm {
      margin: 7% 0;
      font-size: 1.1rem;
    }
    .confirm-message {
      margin: 4% 0;
      font-size: 0.9rem;
    }
    .message {
      margin: 15% 0%;
    }
    .confirm-label {
      color: #ffffff;
    }
    .btn-login {
      cursor: pointer;
      padding: 10px 0;
    }
    button:disabled {
      cursor: not-allowed !important;
    }
    .checked-img {
      position: relative;
      top: 3px;
      right: 12px;
      width: 15px;
      cursor: pointer;
    }
    .unchecked-img {
      position: relative;
      top: 3px;
      right: 12px;
      width: 15px;
      cursor: pointer;
    }
    .no-btn {
      margin-right: 5%;
      background-color: #212124;
    }
    .reset-btn {
      display: flex;
      gap: 20px;
    }
  }
}
}
</style>