<template>
  <popup :width="width" :heightPopup="height" :closePopupEvent="closePopupCB">
    <!-- <h6 slot="header">{{header}}</h6> -->
    <div class="session-exp-popup">
      <div class="popup-form">
        <div class="sec-1">
          <p class="heading">Delete Account</p>
          <p class="question">Are you sure you want to delete your account?</p>
        </div>
        <div class="sec-2">
          <div class="subsec-1">
            <div>If you delete your account:</div>
            <ul>
              <li class="tc">
                All your data associated with {{ this.appname }} account will be
                deleted from our servers.
              </li>
              <li class="tc">
                If you change your mind, you can always come back and open a new
                account with us.
              </li>
              <li class="tc">
                You won’t be able to access linked apps in other platforms like
                Android, iOS app.
              </li>
            </ul>
            <div class="confirm">
              Are you sure you want to delete your account?( This Can’t be
              undone)
            </div>
          </div>
        </div>
        <div class="confirm-selection">
          <img
            v-if="!disabled"
            class="checked-img"
            src="@/assets/icons/checked.svg"
            @click="checked()"
          />
          <img
            v-if="disabled"
            class="unchecked-img"
            src="@/assets/icons/unchecked.svg"
            @click="checked()"
          />
          <label class="confirm-label">Yes, I want to delete my account</label>
        </div>
        <div class="confirm-message">
          Your account will be deleted with immediate effect as soon as Delete
          account is submitted.
        </div>
        <div class="buttons">
          <div class="btn">
            <button
              class="btn-login"
              @click="confirmdelete()"
              :disabled="disabled ? true : false"
            >
              DELETE ACCOUNT
            </button>
            <button type="submit" class="btn-login no-btn" @click="actDone()">
              CANCEL
            </button>
            <app-loader v-show="showLoading"></app-loader>
          </div>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";

export default {
  name: "delete",
  props: {
    closePopupCB: {
      type: Function,
    },
    appname: {
      type: String,
    },
    page: {
      type: String,
    },
  },
  data() {
    return {
      width: "35%",
      height: "55%",
      disabled: true,
      showLoading: false
    };
  },

  methods: {
    ...mapActions(["actDeleteUser", "actLogout"]),
    confirmdelete() {
      this.showLoading = true;
      this.actDeleteUser().then((res) => {
        console.log("RES", res);
        this.showLoading = false;
        this.logoutCB();
      });
    },
    logoutCB() {
      let GA = {
        MenuItem: "SignOut",
      };

      console.log("LT");

      // this.MenuEvent(GA);
      // this.showLoading = true;

      let loginType = JSON.parse(localStorage.getItem("loginType"));

      if (loginType && loginType.type === "FB") {
        FB.logout(function (response) {
          // user is now logged out
        });
      } else if (loginType && loginType.type === "GOOGLE") {
        // gapi.auth2.getAuthInstance().disconnect();
        // let auth2 = gapi.auth2.getAuthInstance();
        // auth2.signOut().then(() => {
        //   auth2.disconnect();
        // });

        gapi.load("auth2", () => {
          let auth2 = gapi.auth2.init({
            client_id: _googleId,
            cookiepolicy: "single_host_origin",
            scope: "profile",
            prompt: "select_account consent",
          });

          auth2.signOut().then(() => {
            auth2.disconnect();
          });
        });
      }

      EventBus.$emit("logOut");
      // this.closePopup();
      console.log("CALLED");
    },
    checked() {
      this.disabled = !this.disabled;
      console.log(this.disabled);
    },
    actDone() {
      this.closePopupCB();
    },
  },
  components: {
    popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue"),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      ),
  },
  mixins: [appMixins],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.session-exp-popup {
  text-align: center;
  .popup-form {
    padding: 2% 15%;
    .heading {
      font-size: 1.5rem;
      font-weight: bold;
      color: #ffffff;
    }
    .question {
      font-size: 1.2rem;
      font-weight: bold;
      margin: 7% 0;
      color: #ffffff;
    }
    .sec-2 {
      font-size: 1rem;
    }
    .image {
      width: 25%;
    }
    .tc {
      list-style: disc;
      padding: 10px 0px;
      color: #ffffff;
    }
    .confirm {
      margin: 7% 0;
      font-size: 1.1rem;
    }
    .confirm-message {
      margin: 4% 0;
      font-size: 0.9rem;
    }
    .message {
      margin: 15% 0%;
    }
    .confirm-label {
      color: #ffffff;
    }
    .btn-login {
      cursor: pointer;
      padding: 10px 0;
    }
    button:disabled {
      cursor: not-allowed !important;
    }
    .checked-img {
      position: relative;
      top: 3px;
      right: 12px;
      width: 15px;
      cursor: pointer;
    }
    .unchecked-img {
      position: relative;
      top: 3px;
      right: 12px;
      width: 15px;
      cursor: pointer;
    }
    .no-btn {
      margin-right: 5%;
      background: #212124;
    }
    .reset-btn {
      display: flex;
      gap: 20px;
    }
  }
}
</style>