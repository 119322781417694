<template>
  <popup :width="width" :closePopupEvent="closePopupCB">
    <div slot="header">
      <h6 class="login-header">Login with</h6>
    </div>
    <div class="session-exp-popup">
      <div>
        <div class="social-logo" :style="disableClick">
          <div class="vlt-login"  id="facebooklogin" @click="signupFacebookCB">
            <img
              src="@/assets/icons/facebook_login.svg"
              class="login-img"
              alt="facebook logo"
              loading="lazy"
            />
          </div>
          <div id="googlelogin" class="vlt-login">
            <img
              src="@/assets/icons/google_login.svg"
              @click="initGoogleSignupAPI"
              alt="google logo"
              class="login-img"
              loading="lazy"
            />
          </div>
          <!-- <div id="applelogin" class="vlt-login">
            <img src="@/assets/icons/apple_login.svg" @click="initAppleLogin" alt="apple logo" loading="lazy"/>
          </div>-->
        </div>
        <div class="vlt-signup-divd">
          <p class="or">or</p>
        </div>
        <!-- Form -->
        <div class="login-form">
          <form @submit.prevent="loginUserCB" autocomplete="off">
            <div class="vlt-lgn-form">
              <div class="vlt-input-group">
                <label>Email</label>
                <input
                  id="userEmail"
                  type="email"
                  aria-label="email"
                  v-model="userEmail"
                  placeholder="Enter email address"
                  ref="userEmail"
                  maxlength="128"
                  tabindex="1"
                />
                <p class="login-error-message">{{ userEmailError }}</p>
              </div>

              <!-- Password Input -->
              <div class="vlt-input-group input-ico">
                <label>Password</label>
                <div class="vlt-input">
                  <input
                    id="password"
                    type="password"
                    aria-label="password"
                    v-model="password"
                    placeholder="Enter password"
                    autocomplete="off"
                    maxlength="16"
                    tabindex="2"
                  />
                  <span class="ico-img" style="cursor: pointer">
                    <img
                      :src="iconEyeOpenPath"
                      @click="toggleInputMode"
                      alt="eye img"
                      loading="lazy"
                    />
                  </span>
                </div>
                <p class="login-error-message">{{ passwordError }}</p>
              </div>

              <!-- Forgot Password -->
              <p class="vlt-link vlt-lgn-fgtpwd">
                <span @click="() => showResetPasswordPopup(true)"
                  >Forgot Password?</span
                >
              </p>

              <!-- Buttons -->
              <div class="vlt-btn" :style="disableClick">
                <button
                  type="submit"
                  class="btn-login"
                  ref="loginBtnSubmit"
                  aria-label="Sign In"
                >
                  Login
                </button>
              </div>
              <p class="new-user text-danger" v-if='!cookiePermission'>Please accept cookies to login.</p>
            </div>
          </form>
        </div>
        <app-loader class="loader" v-show="showLoading"></app-loader>

        <div class="vlt-signup">
          <p class="new-user">Don't have an Account?</p>
          <button
            :style="disableClick"
            @click="openSignUpPopup"
            class="signup"
            ref="signupBtn"
            aria-label="Sign Up"
          >
            Sign up
          </button>
        </div>
      </div>
      <form-error v-show="formError" :message="formError"></form-error>
    </div>
  </popup>
</template>

<script>
import appMixins from "@/mixins/appMixins";
import GoogleAnalytics from "@/mixins/GoogleAnalytics";
import { _googleId } from "@/module/vendor-config";
import { EventBus } from "@/main";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "LoginForm",
  data() {
    return {
      width: "30%",
      userEmail: undefined,
      password: undefined,
      iconEyeOpenPath: require("@/assets/icons/eye_open.svg"),
      iconEyeClosePath: require("@/assets/icons/eye_closed.svg"),
      showLoading: false,
      formError: null,
      userEmailError: "",
      passwordError: "",
      social: {},
      facebookToken: "",
      content: null,
      purchase: null,
      subscriber: null,
      flagAutologin: true,
    };
  },
  props: ["closePopupCB", "previousActionData"],
  computed: {
    ...mapGetters([
      "subscriberId",
      "appMessages",
      "appConfig",
      "detailCardStatus",
      "ticketResponse",
      "currentContent",
      "cookiePermission"
    ]),
    disableClick(){
      return !this.cookiePermission?{pointerEvents:'none',opacity:'0.5'}:''
    }
  },
  watch: {
    formError(value) {
      if (value) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
    userEmail() {
      if (this.userEmailError) {
        this.userEmailError = "";
      }
    },
    password() {
      if (this.passwordError) {
        this.passwordError = "";
      }
    },
  },
  methods: {
    ...mapActions([
      "actGetSubscriber",
      "actGetPlans",
      "actGetTicket",
      "actListTrailerContents",
      "actGetContent",
    ]),
    ...mapMutations(["commitTicketResponse", "commitCurrentContent"]),

    // 458080364267-r3r16jn2flj2pkgd45820ftpri45c2kv.apps.googleusercontent.com //vlite
    // 533474222306-hqjlglom7v10m42pc4l276e4i2u6jmki.apps.googleusercontent.com //cave
    initGoogleSignupAPI() {
      if(!this.cookiePermission)return;
      gapi.load("auth2", () => {
        let auth2 = gapi.auth2.init({
          client_id: _googleId,
          cookiepolicy: "single_host_origin",
          scope: "profile",
        });

        auth2.attachClickHandler(
          document.getElementById("googlelogin"),
          {},
          (user) => {
            var profile = user.getBasicProfile();
            if (profile.getId()) {
              let token = user.getAuthResponse().id_token;
              this.social = {
                app: "GOOGLE",
                token: token,
              };
              this.showLoader();
              
              EventBus.$emit("loginUser", this.social, "L");
           
            }
          },
          (error) => {}
        );
      });
    },
    signupFacebookCB() {
      if (this.showLoading || !this.cookiePermission) return;
      let self = this;
      FB.login(
        function (response) {
          if (response.authResponse) {
            console.log("auth token", response.authResponse);
            let token = response.authResponse.accessToken;
            self.facebookToken = token;
            let userId = response.authResponse.userID;

            self.social = {
              app: "FB",
              token: token,
            };
            FB.api(
              "/me",
              "GET",
              { locale: "en_US", fields: "id,name,email" },
              function (response) {
                if (response) {
                  console.log("response result", response);
                  self.showLoader();
              
                  EventBus.$emit("loginUser", self.social, "L");
                  
                }
              }
            );
          }
        },
        { scope: "public_profile,email" }
      );
    },
    // initAppleLogin() {
    //   AppleID.auth.init({
    //     clientId: "com.mobiotics.web.vLite",
    //     scope: "name email",
    //     redirectURI: "https://vlite-ent.firebaseapp.com/",
    //     state: "qvlmvpyqBmyNx0x7MvBJ3DG7obdbUZ",
    //     usePopup: true //or false defaults to false
    //   });
    //   const buttonElement = document.getElementById("applelogin");
    //   buttonElement.addEventListener("click", () => {
    //     const data = AppleID.auth.signIn().then(
    //       response => {
    //         console.log("response", response);
    //         if (response.authorization.id_token) {
    //           let token = response.authorization.id_token;
    //           this.social = {
    //             app: "APPLE",
    //             token: token
    //           };
    //           this.showLoader();
    //           EventBus.$emit("loginUser", this.social, "L");
    //         }
    //       },
    //       err => {
    //         console.log.error(err);
    //       }
    //     );
    //   });
    //   /*document.addEventListener('AppleIDSignInOnSuccess', (data) => {
    //     //handle successful response
    //     console.log("data...", data);
    //   });
    //   document.addEventListener("AppleIDSignInOnFailure", error => {
    //     //handle error.
    //       console.log('error', error);
    //   });*/
    // },
    showResetPasswordPopup(state) {
      if(!this.cookiePermission)return;
      EventBus.$emit("ResetPasswordPopup", state);
    },
    openSignUpPopup() {
      if(!this.cookiePermission)return;
      this.closePopupCB();
      EventBus.$emit("SignPopup", this.previousActionData);
    },
    showLoader() {
      this.showLoading = true;
      this.$refs.loginBtnSubmit.disabled = true;
      this.$refs.signupBtn.disabled = true;
    },
    loginUserCB() {
      if(!this.cookiePermission)return;
      if (!this.isFormValid()) return;
      document.getElementById("googlelogin").style.pointerEvents = "none";
      this.showLoader();
      
      EventBus.$emit(
        "loginUser",
        {
          email: this.userEmail,
          password: this.password,
          app: "SELF",
        },
        "L"
      );
      
    },
    isFormValid() {
      return this.validateEmailInput() && this.validatePassword();
    },
    validateEmailInput() {
      let maxChar = 128;
      if (!this.userEmail) {
        this.userEmailError =
          this.appMessages && this.appMessages.emptyFieldEmail
            ? this.appMessages.emptyFieldEmail
            : "Please enter Email ID";
        return false;
      } else if (!this.validateEmail(this.userEmail)) {
        this.userEmailError =
          this.appMessages && this.appMessages.invalidEmail
            ? this.appMessages.invalidEmail
            : "The email id you entered is invalid";
        return false;
      } else if (this.userEmail.length > maxChar) {
        this.userEmailError = `E-mail must be less than ${maxChar} characters`;
        return false;
      } else {
        this.userEmailError = "";
        return true;
      }
    },
    validatePassword() {
      let minChar = 8;
      let maxChar = 16;

      if (!this.password) {
        this.passwordError =
          this.appMessages && this.appMessages.emptyFieldPassword
            ? this.appMessages.emptyFieldPassword
            : "Please enter Password";
        return false;
      } else if (
        this.password.length < minChar ||
        this.password.length > maxChar
      ) {
        this.passwordError =
          this.appMessages && this.appMessages.invalidPassword
            ? this.appMessages.invalidPassword
            : `Password should be ${minChar} - ${maxChar} characters long`;
        return false;
      } else {
        this.passwordError = "";
        return true;
      }
    },

    toggleInputMode(_event) {
      let target = _event.target;
      let parentNode = target.parentNode.parentNode;
      let inputElm = parentNode.getElementsByTagName("input")[0];
      if (inputElm.type === "password") {
        inputElm.type = "text";
        target.src = this.iconEyeClosePath;
      } else {
        inputElm.type = "password";
        target.src = this.iconEyeOpenPath;
      }
    },
    checkDeepLink() {
      if (this.currentContent && this.currentContent.deeplink) {
        EventBus.$emit("opendeeplink");
      } else if (this.currentContent) {
        // EventBus.$emit("openDetailCard", {detailCard:this.currentContent});
        this.routeToCardView(this.currentContent.contentid);
      }
    },
    navigateTo() {
      if (this.subscriberId) {
        let data = {};
        data.subscriberid = this.subscriberId;

        let subscriberPromise = this.actGetPlans(data).then((response) => {
          if (
            (response.error && response.error === 9104) ||
            (response.planstatus &&
              response.planstatus !== "ACTIVE" &&
              response.planstatus !== "TRIAL" &&
              response.planstatus !== "CANCEL")
          ) {
            return true;
          } else return false;
        });

        let ticketPromise = this.actGetTicket()
          .then((response) => {
            if (!response.error) {
              this.commitTicketResponse(response);
              return response;
            } else return false;
          })
          .catch((error) => {
            console.log("error ticket ", error);
            if (error.status === 401) {
              // this.unauthorisedHandler();
              if (this.flagAutologin) {
                this.commonAutoLogin(this.navigateTo);
                this.flagAutologin = false;
                return;
              }
            }
          });

        if (
          this.appConfig.featureEnabled.subscription &&
          this.appConfig.featureEnabled.purchase
        ) {
          Promise.all([subscriberPromise, ticketPromise]).then((res) => {
            if (!res[0]) return this.checkDeepLink();
            if (
              this.currentContent &&
              res[1] &&
              res[1].some(
                (content) => content.contentid === this.currentContent.contentid
              )
            )
              return this.checkDeepLink();

            if (this.currentContent)
              this.emitPayment({
                intermediate: true,
                menuClicked: true,
              });
            else
              this.emitPayment({
                subscription: true,
                menuClicked: true,
              });
          });
        }
        if (
          this.appConfig.featureEnabled.subscription &&
          !this.appConfig.featureEnabled.purchase
        ) {
          subscriberPromise.then((response) => {
            if (
              (response.error && response.error === 9104) ||
              (response.planstatus &&
                response.planstatus !== "ACTIVE" &&
                response.planstatus !== "TRIAL" &&
                response.planstatus !== "CANCEL")
            ) {
              this.emitPayment({
                subscription: true,
                menuClicked: true,
              });
            }
          });
        }
        if (
          this.appConfig.featureEnabled.purchase &&
          !this.appConfig.featureEnabled.subscription
        ) {
          ticketPromise.then((response) => {
            if (
              response &&
              this.currentContent &&
              response.some(
                (content) => content.contentid === this.currentContent.contentid
              )
            )
              return;
            if (!this.currentContent) return;
            else
              this.emitPayment({
                purchase: true,
                menuClicked: true,
              });
          });
        }
      }
    },
    emitPayment(data) {
      if ( this.currentContent ){
        if( this.currentContent.context__ ) data = this.currentContent.context__ ;
        delete this.currentContent.context__ ;
        data.content = this.currentContent;
      } 
      this.commitCurrentContent(null);
       data.autoAction=true;
      EventBus.$emit("paymentInitPopup", data);
    },
  },
  beforeDestroy() {
    EventBus.$off("loginSuccess");
    EventBus.$off("signupSuccess");
    EventBus.$off("loginError");
    EventBus.$off("fbPrompt-closed", this.closePopupCB);
  },
  mounted() {
    window.loadGoogleApi &&
      window.loadGoogleApi().then(() => {
        this.initGoogleSignupAPI();
      });
    // this.initAppleLogin();
  },
  created() {
    EventBus.$on("fbPrompt-closed", this.closePopupCB);
    EventBus.$on("loginError", (error) => {
      console.log("loginError", error);

      let GA = {
        "Event Label": "Failure",
        Status: "FAILURE",
        ErrorCode: error.Code ? error.Code : "",
        ErrorMessage: error.Message,
      };
      this.loginEvent(GA);

      this.showLoading = false;
      if (error.Code === 9101) {
        let obj = {};
        obj.facebookToken = this.facebookToken;
        obj.popupDisplay = true;
        obj.page = "L";
        EventBus.$emit("facebookLogin", obj);
        return;
      }
      this.formError = error.Message;
      this.$refs.loginBtnSubmit.disabled = false;
      this.$refs.signupBtn.disabled = false;
      document.getElementById("googlelogin").style.pointerEvents = "auto";
    });
    EventBus.$on("signupSuccess", () => {
      if (this.detailCardStatus && this.detailCardStatus.content) {
        // EventBus.$emit("openDetailCard", {detailCard:this.detailCardStatus.content});
        this.routeToCardView(this.detailCardStatus.content.contentid);
      }
      else if (!this.currentContent) this.navigateTo();
      else if (
        this.currentContent.price &&
        Array.isArray(this.currentContent.price)
      ) {
        if (
          this.currentContent.price.some(
            (element) => parseInt(element.amount) === 0
          )
        ) {
          this.checkDeepLink();
        } else this.navigateTo();
      } else {
        this.checkDeepLink();
      }
      this.closePopupCB();
    });
    EventBus.$on("loginSuccess", () => {
      let GA = {
        "Event Label": "Success",
        Status: "SUCCESS",
      };
      console.log("GA ", GA);
      this.loginEvent(GA);

      if (this.facebookToken) {
        let obj = {};
        obj.popupDisplay = false;
        EventBus.$emit("facebookLogin", obj);
      }

      this.showLoading = false;
      document.getElementById("googlelogin").style.pointerEvents = "auto";
      console.log(">>>>subscriberId-login", this.subscriberId);
      if (this.previousActionData) {
        console.log("loginSuccess-previousActionData", this.previousActionData);
        switch (this.previousActionData.popup) {
          case "ContactPopup":
            EventBus.$emit("ContactPopup");
        }
      }
      this.closePopupCB();

      if (this.detailCardStatus && this.detailCardStatus.content) {
        // EventBus.$emit("openDetailCard", {detailCard:this.detailCardStatus.content});
        this.routeToCardView(this.detailCardStatus.content.contentid);
        return;
      }

      if (!this.currentContent) this.navigateTo();
      else if (
        this.currentContent.price &&
        Array.isArray(this.currentContent.price)
      ) {
        if (
          this.currentContent.price.some(
            (element) => parseInt(element.amount) === 0
          )
        ) {
          this.checkDeepLink();
        } else this.navigateTo();
      } else {
        this.checkDeepLink();
      }
    });
  },
  components: {
    popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue"),
    "form-error": () =>
      import(
        /* webpackChunkName: "formError" */ "@/components/Shared/templates/formError.vue"
      ),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      ),
  },
  mixins: [appMixins, GoogleAnalytics],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";
@import "~sass/modules/_hovereffects.scss";

input::-ms-clear,
input::-ms-reveal {
  display: none;
}
input:invalid {
  outline: none;
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
textarea {
  font-size: 18px;
  font-weight: $font-weight-medium;
}
input {
  caret-color: $font-clr-white;
}
::placeholder {
  color: $font-clr-white-1;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 13px;
}
.session-exp-popup {
  padding: 20px;
}
.vlt-signup-divd {
  p {
    display: block;
    margin: 15px 0;
    color: $font-clr-gray;
    // font-weight: 550;
    font-size: 15px;
    text-align: center;
    font-family: $font-family;
    font-weight: $font-weight-medium;
  }
}
.loader {
  margin-top: 5%;
}
.vlt-login {
  height: 100%;
  cursor: pointer;
}
.login-img {
  @include headerIconsHover;
}
.social-logo {
  margin: auto;
  width: 50%;
  display: flex;
  justify-content: space-around;
  div {
    // flex: 1;
    div {
      cursor: pointer;
      img {
        margin: 10px;
        width: 15%;
        float: left;
      }
      p {
        float: left;
        font-family: $font-family;
        font-weight: $font-weight-medium;
        margin-left: 5px;
      }
    }
  }
}
.login-form {
  margin: 0 10% 0 10%;
}
.vlt-input-group {
  margin: 0 0 10px 0;
  input {
    @include inputFocus;
  }
}
.vlt-lgn-fgtpwd {
  color: $font-clr-white-1;
  font-family: $font-family;
  font-weight: $font-weight-semibold;
  margin-bottom: 15px;
  &:hover {
    text-decoration: underline;
  }
}
.vlt-btn {
  background: none;
}
.vlt-signup {
  padding: 20px;

  :nth-child(1) {
    margin-left: 15%;
  }
  :nth-child(2) {
    margin-left: 10px;
    cursor: pointer;
    font-weight: 600;
  }
  p {
    color: $font-clr-gray;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    float: left;
  }
}
.login-header {
  color: $font-clr-white;
  font-family: $font-family;
  font-weight: $font-weight-semibold;
  font-size: 22px;
  // line-height: 37px;
}
.or {
  color: $font-clr-gray;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 19px;
  // font-weight: 500;
  line-height: 19px;
  text-align: justify;
  opacity: 1;
}
.new-user {
  color: $font-clr-gray;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 1rem;
  line-height: 1.5rem;
}
.signup {
  color: $theme-primary !important;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 18px;
  border: none;
  background: transparent;
  &:hover {
    text-decoration: underline;
  }
}
/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {
@include breakpoint(max600) {
  .session-exp-popup {
    padding: 10px;
  }
  .vlt-input-group {
    margin: 0 0 5px 0;
    label {
      font-size: 14px;
    }
  }
  .login-header {
    font-size: 20px;
  }
  .vlt-signup-divd {
    p {
      margin: 7px 0;
      font-size: 16px;
    }
  }
  input[type="email"],
  input[type="password"],
  input[type="text"] {
    height: 30px;
  }
  .vlt-lgn-fgtpwd {
    margin-bottom: 8px;
    font-size: 12px;
  }
  button {
    padding: 8px;
    font-size: 15px;
  }
  .vlt-signup {
    padding: 10px;
    text-align: center;

    :nth-child(1) {
      margin-left: 0%;
    }
    :nth-child(2) {
      margin-left: 0%;
    }
    p {
      display: inline;
      text-align: center;
      float: none;
    }
  }
}
</style>